import React, { useState, useEffect } from 'react';
import { Container, Nav, NavItem, Navbar, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {connect} from 'react-redux';
import {userLogoutRequest} from '../../actions';
import { withRouter } from "react-router-dom";
import '../../assets/scss/header.scss';
import logoSvg from '../../assets/images/centivo-logo.svg';

const tabs = [
  {
    key: "member-search",
    name: "Member-Search"
  },
  {
    key: "provider-directory",
    name: "Provider Directory"
  },
  {
    key: "resources",
    name: "Resources"
  },
  {
    key: "messages",
    name: "Messages"
  }
];

const Header = props => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(props.isUserLoggedIn);

  const logOutUser = () => {
    sessionStorage.removeItem("accessToken")
    sessionStorage.removeItem("idToken")
    sessionStorage.removeItem("loginClient")
    sessionStorage.removeItem("userSignedIn")

    Auth.signOut({ global: true })
      .then(() => {
        props.userLogoutRequest();
        sessionStorage.clear();
        props.history.push("/");
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    setIsUserLoggedIn(props.isUserLoggedIn);
  }, [props.isUserLoggedIn]);

  return (
    isUserLoggedIn && (
      <header className="header fixed-top">
        <Container>
          <Navbar color="light" light expand="md">
            <h1 className="centivo-logo">
              <Link to={"/member-search"}>
                <img
                  src= {logoSvg}
                  alt="Centivo"
                />
              </Link>
            </h1>
            <Collapse navbar>
              <Nav className="ml-auto margin-top-5" navbar>
                {tabs.map(tab => (
                  <NavItem
                    key={tab.key}
                    className={
                      (window.location.href.indexOf("member-details") !== -1 &&
                        tab.key === "member-search") ||
                      window.location.href.indexOf(tab.key) !== -1
                        ? "active"
                        : ""
                    }
                  >
                    <Link to={"/" + tab.key} className="nav-link">
                      {tab.name}
                    </Link>
                  </NavItem>
                ))}
                <NavItem className="logout">
                  <i
                    className="icon icon-logout pointer"
                    onClick={() => logOutUser()}
                  />
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </Container>
      </header>
    )
  );
};

const mapDispatchToProps = dispatch => ({
  userLogoutRequest: () => dispatch(userLogoutRequest())
})

const mapStateToProps = state => ({
  isUserLoggedIn: state.login.userLoggedIn
})


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
