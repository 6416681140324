import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProviderClient } from '@centivo/api-clients'
import { paginateProviderDomainRequest } from '../../utils/apiUtils'

export const fetchDoctorsByServiceLines = createAsyncThunk(
  'providers/fetchDoctorsByServiceLines',
  async ({
    zipcode,
    networks,
    serviceLines,
    gender,
    acceptingNewPatients,
    distance,
  }: any) => {
    try {
      return await paginateProviderDomainRequest('practice/zipCode', {
        zipCode: zipcode,
        networks: networks?.join(',')?.toLowerCase(),
        serviceLines,
        gender,
        allowUnpublished: true,
        distance,
        acceptingNewPatients
      })
    } catch (error) {
      return []
    }
  }
)

export const fetchDoctorsByServiceLinesAndName = createAsyncThunk(
  'providers/fetchDoctorsByServiceLinesAndName',
  async ({
    zipcode,
    name,
    networks,
    serviceLines,
    acceptingNewPatients,
    distance
  }: any) => {
    try {
      const practices = await paginateProviderDomainRequest('provider/zipCode', {
        zipCode: zipcode,
        networks: networks?.join(',')?.toLowerCase(),
        serviceLines,
        name,
        allowUnpublished: true,
        distance,
        acceptingNewPatients,
      })

      const providerClient = new ProviderClient();
      const coordinates = await providerClient.getZipCodeLatAndLong(zipcode)

      return [coordinates, practices]
    } catch (error) {
      return []
    }
  }
)

export const fetchPractitionersByNpi = createAsyncThunk('providers/fetchPractitionersByNpi', async (npi: string | number) => {
  try {
    return await paginateProviderDomainRequest('provider/fetch', {
      npi,
      allowUnpublished: true,
      pageSize: 1000,
      acceptingNewPatients: true,
    })
  } catch (error) {
    return []
  }
})
