import React from 'react';
import { Form, Input, Button, FormGroup, InputGroup } from 'reactstrap'
import { _global } from '../../helpers'
import { Auth } from 'aws-amplify';
import { keyConstants } from '../../constants'
import { LoadingWithOveraly } from '../wrapper/loader';
import { getLoginFailure, getLoginRequest, getLoginSuccess } from '../../actions'
import { connect } from 'react-redux'

class LoginForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: ''
    }

    sessionStorage.removeItem('sessionTimeOut');
    sessionStorage.removeItem('_sessionToken');
  }

  onInputChange = event => {
    const { name, value } = event.currentTarget;
    this.setState({
      [name]: value
    })
  }

  handleSubmit = () => {
    sessionStorage._sessionToken = '';
    sessionStorage.setItem('loginClient', 'cognito');
    const { username, password } = this.state;
    if (username && password) {
      this.props.getLoginRequest();
      Auth.signIn(username, password)
        .then(user => {
          this.props.getLoginSuccess()
          this.props.getUserInfo()
        })
        .catch(err => {
          this.props.getLoginFailure(err);
        });
    }
  }

  render() {
    const handleEnterKeyPress = e => {
      if (e.charCode === keyConstants.ENTER) {
        this.handleSubmit(_global.submit);
      }
    };

    return (
      <Form name="LoginForm" className="input-custom margin-top-20" onKeyPress={handleEnterKeyPress}>
        {this.props.login.fetching && <LoadingWithOveraly style={{ position: 'unset' }} />}

        <FormGroup>
          <label>Email</label>
          <InputGroup>
            <span className="input-group-text">
              <i className="icon icon-mailGray" />
            </span>
            <Input
              className="custom-input"
              name="username"
              placeholder="Enter Username"
              type="text"
              required={true}
              onChange={this.onInputChange}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <label>Password</label>
          <InputGroup>
            <span className="input-group-text">
              <i className="icon icon-lock" />
            </span>
            <Input
              className="custom-input custom-input-password"
              name="password"
              placeholder="Enter Password"
              type="password"
              required={true}
              onChange={this.onInputChange}
            />
          </InputGroup>
        </FormGroup>

        {this.props.login.errMsg ? (<div className="error text-left">{this.props.login.errMsg}</div>) : null}

        <FormGroup>
          <Button
            type="button"
            color="info"
            className="custom-round-btn"
            onClick={this.handleSubmit}
            disabled={this.props.login.fetching || !(this.state.username.trim().length > 0 && this.state.password.trim().length > 0)}
          >
            <i className="icon icon-unlock" /> Login
          </Button>
        </FormGroup>
      </Form>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getLoginSuccess: () => dispatch(getLoginSuccess()),
  getLoginRequest: () => dispatch(getLoginRequest()),
  getLoginFailure: errObj => dispatch(getLoginFailure(errObj))
})

const mapStateToProps = state => ({
  login: state.login
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
