import {
  CHANGE_CONVERSATION_STATUS,
  CREATE_CONVERSATION,
  GET_CONVERSATIONS_FAILURE,
  GET_CONVERSATIONS_REQUEST,
  GET_CONVERSATIONS_SUCCESS,
  GET_MESSAGES_BY_CONVERSATIONID,
  REPLY_MESSAGE,
  UPDATE_MEMBER_MESSAGE_REQUEST
} from '../constants'
import uuid from 'uuid/v4'
import { api } from '../services'

const createConversationAction = (message) => async (dispatch) => {
    dispatch({ type: CREATE_CONVERSATION.START });
    try {
        message = await uploadFilesToS3(message);
        const result = await api.createConversation(message);

        dispatch({ type: CREATE_CONVERSATION.SUCCEED, payload: result.data });
        dispatch(getConversationsByRecipientIdAction(message.recipientId, null, 'inbox'));
    } catch (error) {
        //TODO if 2 users are trying to send the message  together it might fail in that case get the conversationVersion with getMessagesByConversationId(converationId) and update it
        dispatch({ type: CREATE_CONVERSATION.FAIL, payload: error.message });
    }
}

const changeConversationStatusAction = (conversation, filter) => async (dispatch) => {
    dispatch({ type: CHANGE_CONVERSATION_STATUS.START });
    try {
        if (conversation.type === 'legacy') {
            let params = { memberUuid: conversation.recipientId, messageId: conversation.lastMessage.messageId, replyRequired: true, completed: false };

            if (filter === 'inbox') {
                params.replyRequired = false;
                params.completed = true;
            }
            // else if (filter === 'complete') {
            // }
            dispatch({ type: UPDATE_MEMBER_MESSAGE_REQUEST, payload: params })
            dispatch({ type: CHANGE_CONVERSATION_STATUS.SUCCEED, payload: conversation.conversationId });
        } else {
            conversation = { conversationId: conversation.conversationId, recipientId: conversation.recipientId, status: conversation.status }
            const result = await api.changeConversationStatus(conversation);
            dispatch({ type: CHANGE_CONVERSATION_STATUS.SUCCEED, payload: result.data.Attributes.conversationId });
        }
        // dispatch({ type: CHANGE_CONVERSATION_STATUS.SUCCEED, payload: converstaion.conversationId }); //for testing
    } catch (e) {
        dispatch({ type: CHANGE_CONVERSATION_STATUS.FAIL, paylaod: e.message });
    }
}
const replyMessageByConversationIdAction = (message, filter, memberId) => async (dispatch, getState) => {
    dispatch({ type: REPLY_MESSAGE.START });
    try {
        message = await uploadFilesToS3(message);
        let result;
        if (message.conversationId.includes('LOCAL_')) {
            let locallyCreatedConversation = getState().conversations.conversations.Items.find(conversation => conversation.conversationId === message.conversationId);
            locallyCreatedConversation.repliedMessage = message;
            result = await api.createConversationAndReplyToMessage(locallyCreatedConversation);
            dispatch({ type: REPLY_MESSAGE.SUCCEED, payload: { ...message, senderFullName: sessionStorage.getItem('csr_user_email') } });
            if (memberId) {
                dispatch(getConversationsByRecipientIdAction(locallyCreatedConversation.recipientId, null, filter));
            } else {
                dispatch(getConversationsByStatusAction('CSR_ACTION_REQUIRED', null, filter));
            }

        } else {
            //  delete message.topic;
            result = await api.replyMessageByConversationId(message);
            dispatch({ type: REPLY_MESSAGE.SUCCEED, payload: { ...message, senderFullName: sessionStorage.getItem('csr_user_email') } });
        }

        return result;
    } catch (error) {
        console.log(error)
        //TODO if 2 users are trying to send the message  together it might fail in that case get the conversationVersion with getMessagesByConversationId(converationId) and update it
        dispatch({ type: REPLY_MESSAGE.FAIL, payload: error.message });
    }

};

const getConversationsByRecipientIdAction = (recipientId, lastEvaluatedKey, filter) => async (dispatch, getState) => {
    dispatch({ type: GET_CONVERSATIONS_REQUEST, payload: lastEvaluatedKey });
    try {
        const status = filter.toUpperCase(); // === 'inbox' ? 'INCOMPLETE' : 'COMPLETE';
        let tasks = [api.getConversationsByRecipientId(recipientId, lastEvaluatedKey, status)];

        if (!lastEvaluatedKey) {
            tasks.push(api.getMemberInboxMessages({ memberUuid: recipientId, messageFolder: filter }));
            if (status === 'COMPLETE') {
                tasks.push(api.getMessageInfo({ memberUuid: recipientId }))
            }
        }



        let results = await Promise.all(tasks);

        let conversations = results[0].data;

        //fetch all mesages
        while (conversations.LastEvaluatedKey) {
            let moreConversations = await api.getConversationsByRecipientId(recipientId, conversations.LastEvaluatedKey, status);
            conversations = { ...moreConversations.data, Items: [...conversations.Items, ...moreConversations.data.Items] }
        }

        if (lastEvaluatedKey) {
            conversations.Items = [...getState().conversations.conversations.Items, ...conversations.Items]
        }

        let legacyMessages = results[1] ? results[1].data.messages : []; //array of messages

        if (status === 'COMPLETE' && results[2]) {
            legacyMessages = [...legacyMessages, ...results[2].data]
        }

        const listOfConvertedConversations = legacyMessages.map(msg => {
          return {
              conversationId: `LOCAL_${msg.messageId}`,
              conversationVersion: 1,
              creator: msg.recipientId,
              recipientId: msg.recipientId,
              creatorFullName: msg.recipientFirstName + ' ' + msg.recipientLastName,
              topic: msg.subject || msg.msgSubject,
              transactionDate: msg.transactionDate,
              createdTimestamp: msg.transactionDate ? Date.parse(msg.transactionDate) : msg.createdTimestamp,
              type: 'legacy', //used in fetching the message
              status: msg.completed ? 'COMPLETE' : 'INBOX',
              lastMessage: {
                messageId: msg.messageId,
                senderFullName: msg.recipientFirstName + ' ' + msg.recipientLastName,
                senderId: msg.recipientId,
                message: msg.message || msg.msgText,
                attachments: msg.attachments,
                createdTimestamp: msg.transactionDate ? Date.parse(msg.transactionDate) : msg.createdTimestamp,
                'recipientType#notificationType#notificationEvent#notificationId': msg['recipientType#notificationType#notificationEvent#notificationId'],
              }
            };
        })
        conversations.Items = conversations.Items.concat(listOfConvertedConversations);
        //legacy messages ended

        conversations.fetchedAt = Date.now();
        dispatch({ type: GET_CONVERSATIONS_SUCCESS, payload: conversations });
    } catch (error) {
        dispatch({ type: GET_CONVERSATIONS_FAILURE, payload: error.message });
    }
}

const getConversationsByStatusAction = (status, lastEvaluatedKey, filter) => async (dispatch, getState) => {
    dispatch({ type: GET_CONVERSATIONS_REQUEST, payload: lastEvaluatedKey });
    try {
        let tasks = [api.getConversationsByStatus(status, lastEvaluatedKey, 1000)];
        //load more doesn't apply to this
        if (!lastEvaluatedKey) {
            tasks.push(api.getMemberInboxMessages({ messageFolder: filter }))
        }
        let results = await Promise.all(tasks);
        // const results = await api.getConversationsByStatus(status, lastEvaluatedKey);
        let conversations = results[0].data;

        //fetch all messages
        while (conversations.LastEvaluatedKey) {
            let moreConversations = await api.getConversationsByStatus(status, conversations.LastEvaluatedKey, 1000);
            conversations = { ...moreConversations.data, Items: [...conversations.Items, ...moreConversations.data.Items] }
        }

        if (lastEvaluatedKey) {
            conversations.Items = [...getState().conversations.conversations.Items, ...conversations.Items]
        }

        const legacyMessages = results[1] ? results[1].data.messages : []; //array of messages
        const listOfConvertedConversations = legacyMessages.map(msg => {
          return {
              conversationId: `LOCAL_${msg.messageId}`,
              conversationVersion: 1,
              creator: msg.recipientId,
              recipientId: msg.recipientId,
              creatorFullName: msg.recipientFirstName + ' ' + msg.recipientLastName,
              topic: msg.subject,
              createdTimestamp: Date.parse(msg.transactionDate),
              type: 'legacy', //used in fetching the message
              lastMessage: {
                messageId: msg.messageId,
                senderFullName: msg.recipientFirstName + ' ' + msg.recipientLastName,
                senderId: msg.recipientId,
                message: msg.message,
                attachments: msg.attachments,
                createdTimestamp: Date.parse(msg.transactionDate),
                'recipientType#notificationType#notificationEvent#notificationId': msg['recipientType#notificationType#notificationEvent#notificationId']

              },
              member: {
                phoneNumber: msg.primaryPhone,
                emailAddress: msg.email,
                firstName: msg.recipientFirstName,
                lastName: msg.recipientLastName,
                familyId: msg.recipientFamilyId

              }
            };
        })
        conversations.Items = conversations.Items.concat(listOfConvertedConversations);

        dispatch({ type: GET_CONVERSATIONS_SUCCESS, payload: conversations });
    } catch (error) {
        dispatch({ type: GET_CONVERSATIONS_FAILURE, payload: error.message });
    }
}

const getMessagesByConversationIdAction = (conversation, lastEvaluatedKey) => async (dispatch, getState) => {
    //legacy message handler
    if (conversation.conversationId.includes('LOCAL_')) {
        dispatch({ type: GET_MESSAGES_BY_CONVERSATIONID.SUCCEED, payload: { Items: [conversation.lastMessage], fetchedAt: Date.now(), conversationId: conversation.conversationId } });
    } else {
        dispatch({ type: GET_MESSAGES_BY_CONVERSATIONID.START, payload: lastEvaluatedKey });
        try {
            const results = await api.getMessagesByConversationId(conversation.conversationId, lastEvaluatedKey);
            let messages = results.data;
            if (lastEvaluatedKey) {
                messages.Items = [...getState().conversations.messages.Items, ...messages.Items]
            }
            messages.conversationId = conversation.conversationId;
            messages.fetchedAt = Date.now();
            dispatch({ type: GET_MESSAGES_BY_CONVERSATIONID.SUCCEED, payload: messages });
        } catch (error) {
            dispatch({ type: GET_MESSAGES_BY_CONVERSATIONID.FAIL, payload: error.message });
        }
    }

}

const uploadFilesToS3 = async (message) => {
    let attachments = [];
    if (message.files) {
        for (let i = 0; i < message.files.length; i++) {
            let params = {
                Key: `contact-us/attachments/${message.recipientId}/${uuid()}/${message.files[i].name}`,
                operation: 'putObject',
            };
            const getUploadUrlresponse = await api.getPreSignedUrl(params);
            let s3UploadUrl = getUploadUrlresponse.data.url;
            const s3UploadResponse = await api.putObjectInS3WithPreSignedUrl(s3UploadUrl, message.files[i]);
            if (s3UploadResponse.status === 200) {
                attachments.push({
                    Key: params.Key,
                    name: message.files[i].name,
                    size: message.files[i].size,
                    type: message.files[i].type,
                });
            }
        }
        delete message.files;
    }
    message.attachments = attachments;
    return message;
}

const actions = ({
  getConversationsByRecipientIdAction,
  getMessagesByConversationIdAction,
  replyMessageByConversationIdAction,
  createConversationAction,
  getConversationsByStatusAction,
  changeConversationStatusAction,
});

export default actions
