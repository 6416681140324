import { ProviderClient } from "@centivo/api-clients";
import { serviceLinesSlice } from "./slice";

const {
  setDoctorServiceLines,
  setFacilityServiceLines,
  loadDoctorServiceLines,
  loadFacilityServiceLines,
} = serviceLinesSlice.actions

export const fetchDoctorsServiceLines = () => async (dispatch: any) => {
  dispatch(loadDoctorServiceLines());

  const providerClient = new ProviderClient();
  const data = await providerClient.getServiceLines({ isOeDoctor: true, isOeFacility: false});
  const sortedData = data.sort((a: any, b: any) => a.name === b.name ? 0 : a.name < b.name ? -1 : 1);

  dispatch(setDoctorServiceLines(sortedData));
}

export const fetchFacilityServiceLines = () => async (dispatch: any) => {
  dispatch(loadFacilityServiceLines());

  const providerClient = new ProviderClient();
  const data = await providerClient.getServiceLines({ isOeDoctor: false, isOeFacility: true });
  const sortedData = data.sort((a: any, b: any) => a.name === b.name ? 0 : a.name < b.name ? -1 : 1);

  dispatch(setFacilityServiceLines(sortedData));
}
