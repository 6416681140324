import createSagaMiddleware, { END } from 'redux-saga';
import { reducers } from '../reducers';
import { configureStore } from '@reduxjs/toolkit';

const confStore = initialState => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: reducers,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => store.replaceReducer(reducers));
  }

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return store;
};

export default confStore;
