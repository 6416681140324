import React from 'react';
import { Spinner } from 'reactstrap';
import './style/style.scss';

export const Loader = () => (
    <div className="olay">
        <div className="text-center">
            <Spinner className="default-color" />
        </div>
    </div>
)

export const Loading = ({ style }) => <div id="loading" style={style}></div>;
export const LoadingWithOveraly = ({ style = {}, innerStyle = {} }) => <div className='loading-wrapper' style={style}> <div id="loading" style={innerStyle} ></div></div>;
