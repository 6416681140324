import { ProviderClient } from '@centivo/api-clients';
import { specialtiesSlice } from './slice'


const { setSpecialties, loadSpecialties } = specialtiesSlice.actions

export const fetchSpecialties = () => async (dispatch: any) => {
  dispatch(loadSpecialties());

  const providerClient = new ProviderClient();
  const data: any = await providerClient.getSpecialties({});
  const sortedData = data.sort((a: any, b: any) => a.specialty === b.specialty ? 0 : a.specialty < b.specialty ? -1 : 1);

  dispatch(setSpecialties(sortedData));
}
